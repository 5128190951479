@import "../../styles/main.sass";

.contacts-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; }

  .segment {
    padding-top: 0; }

  &__header {
    @include regular();
    @include fontsize-rem(29px);
    color: black;
    text-align: center;
    margin: 20px 0; }

  .ui {
    tbody {
      cursor: pointer; } }

  &__add-contact-drop-down {
    width: 100%; }

  &__drop-zone {
    width: 100%;
    cursor: pointer;
    display: flex;

    &_percent {
      width: 200px;
      height: 23px;
      border-radius: 4px;
      border: 1px solid #c5c5c5;
      margin: 0 auto;
      margin-top: 15px;
      transition: 0.3s; }

    &_percent-count {
      height: 100%;
      background-color: #01b801;
      display: flex;
      align-items: center;
      justify-content: center; }

    &_percent-done {
      color: white;
      font-size: 14px;
      transition: 0.3s; }

    &_wrapper {
      width: 100%;
      min-width: 400px;
      height: 100%;
      border: 3px dotted #bdbcbc;
      padding: 10px;
      background-color: #f7f7f7;
      text-align: center; }

    &_icon {
      font-size: 40px;
      color: cornflowerblue; }

    &_text {
      @include fontsize-rem(17px);
      @include regular();
      margin-bottom: 10px; }

    &_hint {
      @include fontsize-rem(14px);
      @include light(); } }

  &__result {
    margin-left: 10px;
    width: 100%;
    max-width: 750px;
    min-width: 580px;
    height: 100%;
    border: 3px dotted #bdbcbc;
    padding: 10px;
    background-color: #f7f7f7;
    text-align: center;

    &_header {
      @include fontsize-rem(17px);
      @include regular();
      color: black;
      margin-bottom: 10px; }

    &_content {
      width: 100%;
      text-align: left;
      max-height: 140px;
      overflow-x: auto; }

    &_bold {
      @include fontsize-rem(14px);
      @include regular();
      margin-right: 20px;
      border-bottom: 1px solid #9f9f9f;
      margin-bottom: 4px; }

    &_light {
      @include fontsize-rem(14px);
      @include light();
      margin-bottom: 7px;
      word-break: break-all;
      line-height: 18px; } }

  .add-drop-down-component__expand-area {
    display: flex; }


  ::-webkit-scrollbar {
    width: 4px; }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2); }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2); }

  .ui.sortable.table {
    thead {
      th {
        &.not-sortable {
          &:hover {
            background: white; } } } } }

  .table-pagination {
    display: flex;
    justify-content: flex-end; }

  .ant-table-row {
    position: relative;
    .ant-table-cell {
      vertical-align: baseline;
      line-height: 40px; }

    .ant-form-item-label {
      padding: 0; } } }
