@import "../../styles/main.sass";

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;

  &__header {
    width: 100%;
    margin: 40px 0;
    text-align: center; }

  .form {
    width: 500px; }

  .login-span {
    @include fontsize-rem(13px); } }

