@import "../../styles/main.sass";

.robot-creation-component {
  .col-1-1 {
    width: 100%; }

  .col-1-2 {
    width: 50%; }

  .col-1-4 {
    width: 25%; }

  .col-3-4 {
    width: 75%; }

  .col-1-5 {
    width: 20%; }

  .col-2-5 {
    width: 40%; }

  .col-4-5 {
    width: 80%; }

  .gap-align {
    display: flex;
    gap: 20px; }


  & > .ant-tabs {
    min-width: 920px; }

  &__online-robot {
    max-width: 600px;
    width: 600px;
    position: relative;

    &_points {
      height: 100%;
      display: flex;
      align-items: flex-end;
      &__point {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid #00ba00;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        cursor: pointer;
        transition: 0.3s;
        @include fontsize-rem(16px);
        @include regular();

        &_delete {
          display: none; }

        &:hover {
          transition: 0.3s;
          background-color: #bfbfbf;
          .robot-creation-component__online-robot_points__point_delete {
            display: block;
            z-index: 3;
            color: red;
            transition: 0.3s; }
          .robot-creation-component__online-robot_points__point_number {
            display: none;
            transition: 0.3s; } } } }


    &_row {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 12px;
      gap: 20px; }

    &_item {

      .ant-select {
        &::-webkit-scrollbar {
          width: 4px; }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2); }

        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2); } } }

    &_label {
      display: block;
      @include fontsize-rem(13px);
      @include regular();
      margin-bottom: 4px;

      span {
        color: red; } }

    &_map {
      position: relative;
      top: -352px;
      left: 580px;
      height: 300px;
      margin: 0 auto;
      border-radius: 12px;
      overflow: hidden;
      width: 88%; }

    &_map-geo-points {
      top: -420px;
      height: 365px; } }

  &__geo-points {
    &_button {
      height: 30px;
      margin-top: 18px !important; } } }

