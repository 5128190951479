@import "../../styles/main.sass";

.reports-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; }

  .ant-pagination {
    text-align: right;
    margin-bottom: 20px; }

  .ant-table-body {
    overflow-y: auto !important; }

  .ant-table-body-inner {
    overflow-y: auto !important; }

  .table-cell-form {
    &__inputs {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .date_picker {
        margin-top: 3px;
        margin-bottom: 4px;
        margin-right: 8px; }

      .ant-input-group-wrapper {
        margin-top: 4px;
        margin-bottom: 4px;
        margin-right: 8px; }

      .ant-select-selection {
        margin-top: 4px;
        margin-bottom: 4px; }

      .checkbox-other {
        display: flex;
        align-items: center;
        justify-content: center; } }

    &__transfer {
      .ant-transfer-list {
        width: 260px; } } } }

.timezone_report {
  display: block;
  position: absolute;
  padding-top: 20px; }

