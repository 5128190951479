@import "../../../styles/main.sass";

.super-admin-content {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &__header {
    @include regular();
    @include fontsize-rem(29px);
    color: black;
    text-align: center;
    margin: 20px 0; }

  &__grid {
    max-width: 900px;
    margin: 0 auto !important;

    &_button {
      transition: 0.3s !important;
      &:hover {
        background-color: $green !important;
        transition: 0.3s !important;
        color: white !important; } } }

  &__toggle {
    margin-top: 10px; }

  &__create-user-card {
    padding: 1em !important;

    &_content {
      &__name {
        margin-bottom: 10px;
        @include fontsize-rem(16px); } } }

  &__notification, &__create-user-card, &__edit-mail-card {
    width: 100% !important;
    flex-grow: inherit !important; }

  &__notification {
    display: inline-flex;
    padding: 0 30px 0 15px !important;
    justify-content: flex-start !important;

    &_create {
      width: 100% !important;
      padding: 1em !important; }

    &_name {
      display: flex;
      justify-content: flex-start; }

    &_button {
      display: flex !important;
      margin-top: 10px !important; }

    &_textArea {

      textarea {
        margin-top: 10px;
        border-radius: 4px;
        width: 100% !important;
        border: 1px solid #d9d9d9 !important;
        resize: vertical;
        max-height: 100px;
        min-height: 100px;
        outline: none;
        padding: 10px;
        font-size: 14px;

        &:active, &:focus {
          border: 1px solid #85B7D9 !important; } } } }

  &__edit-mail-card {
    padding-left: 15px !important;
    padding-right: 30px !important;

    label {
      text-align: left;
      @include fontsize-rem(14px);
      @include light();
      font-weight: 300 !important; }

    &_content {
      &__name {
        text-align: left;
        margin-bottom: 20px;
        @include fontsize-rem(16px); } }

    &_button {
      margin-left: 8px !important; }

    &_end-field {
      margin-bottom: 0px !important; } }

  &__drop-down {
    margin: 10px 0 0 0;

    .login-span {
      @include fontsize-rem(12px); }

    &.--create-admin {
      width: 35%; }

    &.--app-settings {
      margin-bottom: 30px !important; }

    &_highlight {
      background-color: #a6cce8;
      padding: 1px;
      border-radius: 3px; }

    &_highlight-wrap {
      width: 400px;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      padding: 4px 11px;
      margin-left: 10px;
      line-height: 20px; }

    &_sms-wrap {
      &__variables {
        margin-top: 20px;
        margin-bottom: 20px;

        div {
          margin-bottom: 3px; }

        &_br {
          margin-bottom: 10px !important;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 5px; } }

      &__lock {
        display: flex;
        align-items: center;

        &_text {
          margin-left: 10px; } } } }

  &__app-status {
    flex-grow: inherit !important; }

  &__table-name {
    @include fontsize-rem(24px);
    @include regular(); } }

.text-area-highlight {
  display: flex;
  justify-content: space-between;

  &__text-only {
    width: 49%;
    line-height: 22px; }

  &__highlighted-text {
    display: inline-block;
    width: 49%;
    max-height: 213px;
    line-height: 22px;
    white-space: pre-wrap;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
    overflow: auto;
    font-size: 15px;

    mark {
      background-color: #8ed3ff;
      padding: 0.8px;
      border-radius: 2px;
      font-size: 13px; } } }


