@import "../../styles/main.sass";

.contact-view-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;

  &.--fixed {
    padding-top: 65px; }

  &__left-part {
    width: 30%;

    &_robot-button {
      margin-left: 5px !important; } }

  &__right-part {
    width: 70%;
    position: relative;

    .table-edit {
      margin-bottom: 30px; }

    &_locations-info {
      position: relative;
      width: 100%;
      height: 40px;
      display: flex;
      background-color: $background-card-light !important;
      border-radius: 5px;
      margin-top: 10px;
      box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.08);

      &.--opened {
        box-shadow: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }

      .ant-card-head {
        margin-right: 10px;
        min-height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: none;
        padding: 0 10px;

        .ant-card-head-wrapper {
          .ant-card-head-title {
            font-size: 15px;
            color: #5b5b5b;
            padding: 0; } } }

      .ant-card-body {
        padding: 0;
        display: flex;
        align-items: center;
        .ant-tree {
          display: flex;
          flex-wrap: wrap;

          li {
            padding: 0;
            position: relative;

            .ant-tree-child-tree-open {
              position: absolute;
              top: 30px;
              z-index: 1; } } } }

      &__drop {
        .ant-tree-child-tree {
          background-color: white;
          max-height: 300px;
          overflow-y: scroll;
          width: 220px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); } } }


    &_path {
      position: absolute;
      top: 10px;
      left: 148px;
      width: 350px;
      height: 29px;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;

      &__button {
        background-color: white;
        width: 80px;
        height: 100%;
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include fontsize-rem(11px);
        @include semibold();
        color: rgb(86, 86, 86);
        outline: none;
        cursor: pointer;

        &:hover {
          background-color: rgb(235, 235, 235); }

        &_navigation {
          background-color: white !important;
          padding: 9px !important;
          border-radius: 2px !important;
          box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px !important;
          font-size: 11px !important;
          color: rgb(86, 86, 86) !important;
          outline: none !important;
          margin-right: 0 !important;

          &:hover {
            background-color: rgb(235, 235, 235) !important; } }

        &_play-pause {
          width: 30px;
          height: 100%;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          outline: none;
          border-left: 1px solid #c5c5c5;
          border-right: 1px solid #c5c5c5;

          .icon {
            margin: 0; }

          &:hover {
            background-color: rgb(235, 235, 235) !important; } } } }


    &_radio-group-filter {
      margin-top: 4px;
      box-shadow: none !important;

      .ant-radio-button-wrapper {
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        margin: 0 10px !important;
        padding: 0 !important;

        &:before {
          left: -10px;
          height: 20px;
          top: 50%;
          transform: translateY(-50%); } }

      .ant-radio-button-wrapper-checked {
        border: none !important;
        color: #40a9ff !important;
        box-shadow: none !important;

        &:after {
          content: '';
          position: absolute;
          width: 100%;

          height: 2px;
          background: #40a9ff;
          bottom: 7px;
          left: 50%;
          transform: translateX(-50%); } }


      .ant-dropdown-trigger {
        margin-left: 10px; } }



    &_filter {
      background: $background-card;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;

      &__content {
        height: 0;
        overflow: hidden;
        transition: 0.2s;
        opacity: 0;

        display: flex;
        align-items: center;

        .ant-calendar-picker {
          margin-left: 10px; }

        &.--active-filter {
          padding: 10px 10px 10px 0;
          height: 50px;
          opacity: 1;
          transition: 0.2s; } }

      &__buttons {
        position: absolute;
        cursor: pointer;
        outline: none;

        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        display: flex;
        align-items: center;


        &:hover {
          .contact-view-page__right-part_filter__buttons_label {
            color: #1c9bde;
            transition: 0.2s; }
          i {
            color: #1c9bde; } }


        &_button {
          font-size: 16px !important;
          margin-left: 5px;
          cursor: pointer; }

        &_label {
          @include light();
          @include fontsize-rem(14px); } } } } }

.flag-component {
  img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10); }
  span {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10); } }
