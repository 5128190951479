@import "../../styles/main.sass";

.jobs-list {
  width: 100%;
  background-color: $background-card;
  padding: 15px;
  border-radius: 5px;

  margin-top: 10px;
  margin-bottom: 15px;

  max-height: 550px;
  overflow: auto;
  scroll-behavior: smooth;

  &__wrapper {
    position: relative; }

  &__list-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    &_clear {
      &:hover {
        border-color: #ee3e43;
        color:  #ee3e43; } } }


  &__sort-wrapper {}

  &__sort {
    &_label {
      @include fontsize-rem(12px);
      @include regular-italic();
      margin-right: 10px; } }

  .job-wrapper {
    &:last-child {
      margin-bottom: 0; } } }
