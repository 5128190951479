@import "typography";
@import "../utils/mixins";

body, html {
  @include fontsize-rem(16px);
  @include regular;
  height: 100%;
  width: 100%;
  min-width: 1024px;
  transition: background 0.3s;
  position: relative;
  background: white; }

.app--dark-mode {
  filter: invert(0.9);

  #status-indicator-dot {
    filter: invert(1); } }


.app-base-style {
  .dimmer {
    background-color: rgba(255,255,255,1) !important; } }

.ant-table-body {
  margin: 0 0 !important; }

.ant-table-column-sort {
  background: transparent !important; }

.ant-popover {
  z-index: 1000; }


// ColorPicker Styles
.rcp-body {
  width: 100%; }

.rcp-fields {
  .rcp-fields-floor {
    &:last-child {
      display: none; } } }

.search-component-tree-dropdown {
  .ant-select-tree-switcher, .ant-select-tree-indent {
    display: none; } }

.ant-table {
  &.ant-table-empty {
    .ant-table-container::after {
      display: none; }
    .ant-table-content {
      overflow: hidden !important; } } }


.ant-table-middle,
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 5px !important;
  overflow: hidden !important;

  table {
    padding: 0 !important;
    width: calc(100%);
    border-radius: 4px !important; }

  .ant-table-body {
    overflow-y: hidden !important; }

  .ant-table-thead {
    tr {
      th {
        position: relative;
        background: #efefef !important;

        .anticon-search {
          position: absolute;
          top: 50%; } }

      .ant-table-header-column {
        .ant-table-column-sorters {
          display: flex;
          align-items: center;
          cursor: pointer;

          .on {
            color: rgb(24, 144, 255) !important; } } } } }


  .ant-table-tbody {
    tr {
      &:hover {
        td {
          background: #f6f6f6 !important; } }
      td {
        background: white; } } } }

@media only screen and (max-width: 1240px) {
  .ant-table-middle,
  .ant-table-small {
    .ant-table-thead, .ant-table-tbody {
      tr {
        th {
          font-size: 11px; }
        td {
          font-size: 12px; } } } } }
.grey-wrapper {
  width: 100%;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 5px; }

.ant-table-middle,
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 5px !important;
  overflow: hidden !important;

  table {
    padding: 0 !important;
    width: calc(100%);
    border-radius: 4px !important; }

  .ant-table-body {
    overflow-y: hidden !important; }

  .ant-table-thead {
    tr {
      th {
        position: relative;
        background: #efefef !important;

        .anticon-search {
          position: absolute;
          top: 50%; } }

      .ant-table-header-column {
        .ant-table-column-sorters {
          display: flex;
          align-items: center;
          cursor: pointer;

          .on {
            color: rgb(24, 144, 255) !important; } } } } }


  .ant-table-tbody {
    tr {
      &:hover {
        td {
          background: #f6f6f6 !important; } }
      td {
        background: white; } } } }

.ant-notification {
  z-index: 1052; }

.ant-message {
  z-index: 1052; }
