@import "../../styles/main.sass";

.search-component {
  width: 100%;
  background-color: $background-card;
  padding: 15px;
  border-radius: 5px;

  display: flex;
  align-items: center;

  &__left-content {
    width: 68%;
    display: flex; }

  &__right-content {
    width: 32%;
    display: flex;
    justify-content: flex-end;

    // Button OR Button for Locations search type
    .button {
      padding: 7px 22px !important;
      height: 34px !important; }

    .buttons {
      // OR Circle
      .or {
        height: 32px;

        &:before {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 17px;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          margin-top: 0;
          margin-left: 0; } } } }


  .ant-select {
    width: 200px;
    margin-right: 20px; }

  &__inputs {
    margin: 0 20px;
    display: flex;

    .ant-input-affix-wrapper {
      input {
        padding-right: 25px; }

      .ant-input-suffix {
        line-height: 18px;
        right: 5px;

        i {
          font-size: 12px;
          color: #ababab; } }

      &:first-child {
        margin-right: 10px; } } } }

