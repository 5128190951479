@import "../../styles/main.sass";

.white-list-table-component {
  margin-top: 15px;

  table {
    td {
      padding: 6px 16px; } }

  &__list {
    display: flex;
    flex-wrap: wrap;

    &_item {
      padding: 2px 5px;
      border-radius: 3px;
      margin-right: 10px;
      margin-bottom: 5px;

      &.--whitelist-msisdn {
        background-color: #dfdfdf; }

      &.--whitelist-imsi {
        background-color: #c1c1c1; }

      &_delete {
        color: black;
        padding: 3px 8px;
        cursor: pointer;

        &:hover {
          color: red; } } } } }
