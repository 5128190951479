@import "../../styles/main.sass";

.dashboard-robots {
  width: 100%;
  padding: 15px;
  background: $background-card;
  border-radius: 5px;
  margin-bottom: 15px;

  &__create-wrapper {
    margin-bottom: 10px; }

  &__create-action {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      color: #40a9ff; }

    .anticon {
      margin-right: 5px; }

    &_label {
      font-size: 14px; } }

  &__create-form {
    max-width: 950px;

    .robot-creation-component__online-robot {
      max-height: 500px; }

    .robot-creation-component__online-robot_map {
      left: 450px;
      width: 52%; } }

  .ant-pagination {
    position: absolute;
    margin: 0;
    top: -34px;
    right: 0; }

  .ant-table-small {
    .ant-table-container {
      width: calc(100% + 2px); } } }
