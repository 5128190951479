@import "../../styles/main.sass";

.contact-view-page__left-part_comments {
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;

  width: 90% !important;
  min-width: 290px !important;
  max-width: 320px !important;

  .ant-card-head {
    min-height: 35px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    .ant-card-head-title {
      padding: 0; } }

  &__title {
    @include fontsize-rem(10px);
    @include light();
    color: grey;
    margin-left: 10px; }

  &__input {
    width: 100%;

    &_text {
      width: 100%;
      border: 0;
      margin: 7px 0;
      outline: none;

      &::placeholder {
        color: #b8b8b8;
        @include fontsize-rem(14px);
        @include light-italic(); } } }

  .ant-card-body {
    padding: 0 15px;
    max-height: 220px;
    overflow-y: auto;

    .ant-list {
      .ant-list-empty-text {
        display: none !important; } }

    .ant-list-item {
      padding: 5px 0;

      .ant-list-item-meta-description {
        position: relative;
        line-height: 18px;

        .delete-comment {
          position: absolute;
          top: -25px;
          right: -5px;

          &__button {
            color: grey;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              color: red;
              transition: 0.3s; } } } } } } }



.card {
  width: 100%;
  &__title {
    font-weight: 100; } }
