.leaflet-map-component {
  .leaflet-container {
    width: 100%;
    height: 400px;
    z-index: 1; }


  .leaflet-control-attribution {
    a {
      display: none; } } }

.app--dark-mode {
  .leaflet-fullscreen-on {
    filter: invert(0.9); } }
