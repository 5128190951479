@import "../../styles/main.sass";

.dashboard-tables {
  width: 100%;
  padding: 15px;
  background: $background-card;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  &__left {
    width: 49%; }

  &__right {
    width: 49%; }

  &__create-wrapper {
    margin-bottom: 10px; }

  &__create-action {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      color: #40a9ff; }

    .anticon {
      margin-right: 5px; }

    &_label {
      font-size: 14px; } }

  &__create-form {
    width: 500px;

    &--group {
      width: 300px; }

    .ant-form-item-control {
      line-height: 16px; }

    .ant-col {
      .ant-row {
        margin-bottom: 0; } }

    .ant-row {
      margin-bottom: 10px;

      textarea {
        resize: none; } }

    &_label {
      margin-right: 10px;
      font-weight: 600; } }


  .table-component {
    .ant-table-wrapper {
      margin-top: 40px; }

    .ant-pagination {
      position: absolute;
      margin: 0;
      top: -34px;
      right: 0; }

    &--fix-empty-height {
      height: 230px;

      .ant-table-wrapper {
        height: calc(100% - 59px);

        .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-content {
          height: 100%; }

        .ant-table-placeholder {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);

          td {
            border-bottom: none; } } } } }


  &__table {
    &_actions-wrapper {
      text-align: center;
      cursor: pointer;
      width: 44px;
      margin: 0 auto;

      display: flex;
      align-items: center;

      .dashboard-tables__table_action-delete, .dashboard-tables__table_action-enter {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #eaeaea;
          border-radius: 50%;
          color: red; } }

      .dashboard-tables__table_action-enter {
        &:hover {
          color: rgb(24, 144, 255); } } } }

  @media only screen and (max-width: 1144px) {
    flex-direction: column;

    &__left {
      width: 100%; }

    &__right {
      width: 100%; }

    .ant-table-thead, .ant-table-tbody {
      tr {
        th {
          font-size: 11px; }
        td {
          font-size: 12px; } } } }

  .ant-table-small {
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    overflow: hidden !important;

    table {
      padding: 0 !important;
      width: calc(100%);
      border-radius: 4px !important; }

    .ant-table-body {
      overflow-y: hidden !important; }

    .ant-table-thead {
      tr {
        th {
          position: relative;
          background: #efefef !important;

          .anticon-search {
            position: absolute;
            top: 50%; } }

        .ant-table-header-column {
          .ant-table-column-sorters {
            display: flex;
            align-items: center;
            cursor: pointer;

            .on {
              color: rgb(24, 144, 255) !important; } } } } }


    .ant-table-tbody {
      tr {
        &:hover {
          td {
            background: #f6f6f6 !important; } }
        td {
          background: white; } } } }

  @media only screen and (max-width: 1240px) {
    flex-direction: column;

    &__left {
      width: 100%; }

    &__right {
      width: 100%;
      border-top: 5px solid white;
      margin-top: 20px;
      padding-top: 15px; } } }
