@import "../../styles/main.sass";

.license-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; } }
