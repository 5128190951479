@import "../../../../styles/main.sass";

.job-item-contacts {
  width: 15%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__popup {
    .ant-popover-inner-content {
      max-height: 250px;
      overflow: auto; }
    .ant-popover-inner-content {
      a {
        display: block;
        color: #3b3b3b;
        &:hover {
          color: #1589e0; } } } }

  &__logos-wrapper {
    position: relative;
    display: flex;
    align-items: center; }

  &__logos {
    width: 100%;
    height: 25px;

    display: flex;
    justify-content: center;

    &--empty {
      opacity: .4;

      .job-item-contacts__logos_circle {
        &:hover {
          top: 0;
          box-shadow: none;
          cursor: initial; } } }

    &_circle {
      position: relative;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #8d8d8d;
      margin: 0 2px;

      cursor: pointer;

      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2px;

      &:hover {
        top: -2px;
        box-shadow: 0 3px 4px 0 rgba(21, 27, 38, 0.15);
        box-sizing: border-box;
        transition: box-shadow 100ms,transform 100ms,background-color 100ms,border-color 100ms; }

      &:nth-child(2) {
        background: #b0b0b0; }

      &:nth-child(3) {
        background: #d4d4d4; } } }

  &__more {
    position: absolute;
    right: -25px;
    color: #33adfe;
    @include fontsize-rem(12px);
    margin-left: 3px;
    cursor: pointer; }

  &__text {
    width: 100%;
    @include fontsize-rem(10px);
    text-align: center;
    color: #666666;

    span {
      font-weight: 600;
      margin-right: 2px; } } }
