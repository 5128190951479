//@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic)
//@import url(https://fonts.googleapis.com/css?family=Montserrat)

@import "../utils/mixins";
@import "../utils/colors";
// Create mixins for fonts
@mixin light {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1em; }
@mixin light-italic {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: italic;
  line-height: 1em; }
@mixin regular {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1em; }
@mixin regular-italic {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: italic;
  line-height: 1em; }
@mixin semibold {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em; }
@mixin semibold-italic {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: italic;
  line-height: 1em; }
@mixin bold {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1em; }
@mixin bold-italic {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: italic;
  line-height: 1em; }
@mixin heading {
  font-family: "Playfair Display", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 900;
  line-height: 1em; }

@mixin montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1em; }

h1 {
  @include fontsize-rem(24px);
  @include bold;
  color: $text;
  margin: 20px 0;
  line-height: 1.5em; }
h2 {
  @include fontsize-rem(22px);
  @include bold;
  color: $text;
  margin: 10px 0;
  line-height: 1.5em; }

h3 {
  @include fontsize-rem(18px);
  @include bold;
  color: $text;
  margin: 20px 0;
  line-height: 1.5em; }

h4 {
  @include fontsize-rem(15px);
  @include bold;
  color: $text;
  margin: 20px 0;
  line-height: 1.5em; }
p {
  @include fontsize-rem(13px);
  @include regular;
  color: $text;
  line-height: 1.7em;
  margin: 0; }

strong.emphasize-letter {
  color: $blue;
  //text-shadow: 1px 1px 1px $green2
  padding-right: 1px; }

.text-grey {
  color: $greyish; }

.text-small {
  @include fontsize-rem(12px); }
