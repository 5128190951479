@import "../../styles/main.sass";

.robot-info-component {
  margin: 0 20px 20px 0!important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;

  &__header {
    margin-bottom: 0;
    line-height: 22px !important; }

  &__header-type {
    font-size: 14px;
    font-weight: 400;
    color: #b6b6b6;
    margin: 0 0 10px 0; }


  &__description-title {
    position: relative;
    margin-bottom: 10px;
    color: black;

    &_description {
      @include fontsize-rem(14px);
      color: black;
      @include regular(); }

    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e0e0e0;
      margin: 3px 0; }

    &_info {
      color: $grey-a; } }

  &__info {
    @include fontsize-rem(15px);

    &_text {
      color: #676767;

      img {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10); } } }


  .meta {
    div {
      margin: 5px 0; } }

  .description {
    color: rgba(0, 0, 0, 0.4)!important; }

  .button {
    width: 100%; } }
