// Greyscale tones
$grey-1:  #111;
$grey-2:  #222;
$grey-3:  #333;
$grey-4:  #444;
$grey-5:  #555;
$grey-6:  #666;
$grey-7:  #777;
$grey-8:  #888;
$grey-9:  #999;
$grey-a:  #aaa;
$grey-b:  #bbb;
$grey-c:  #ccc;
$grey-d:  #ddd;
$grey-e: #f1f1f1;
$grey-placeholder: #a2a2a2;

$white: white;
$pale-red: #d65a3c;
$text-default: #28152c;
$charcoal-grey: #2c2e33;
$greyish: #a5a5a5;
$apple: #66c64d;


$primary: #389cff;
$blue: #006cd6;
$blue2: #f5f9ff;
$blue3: #0080ff;
$blue4: #337ab7;
$yellow: #fff000;
$yellow2: #fffeee;
$yellow3: #dfd143;
$green: #9ed459;
$green2: #8cc73f;
$green3: #ecfed5;
$green4: #eef8f7;
$green-sidebar: #c9f17c;
$red: #ef3838;

$sidebar: #454c59;
$hamburger: #5b616e;
$text: #21252e;
$grey-box: #f9f8f8;
$footer-bg: #ebf5ff;
$sidebar-separator: #757a85;
$form-label: #2e2e2e;
$background-card: #f9f9f9;
$background-card-light: #fbfbfb;
//$background-card: #f9f9f9
//$background-card: #f5f5f5


$periwinkle: #8c67ff;

.bg-white {
  background-color: $white; }
