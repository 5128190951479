@import "../../styles/main.sass";

.dashboard-page-new {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; } }

.dashboard-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &_notice {
    width: 100%;

    &_inner {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 4px;
      padding: 10px 15px;

      h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 1em; }

      hr {
        margin: 0;
        border: 1px solid rgba(34, 36, 38, 0.15); }

      p {
        margin-top: 5px;
        font-size: 14px; } } }

  &.--fixed {
    padding-top: 65px; }


  &__header {
    @include regular();
    @include fontsize-rem(29px);
    color: black;
    text-align: center;
    margin: 20px 0; }

  &__form {
    position: relative;
    max-width: 1160px !important;
    min-width: 1000px !important;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;

    &_input {
      margin-right: 20px;
      width: 100%; }

    &_imsi-result {
      width: 100%;
      @include fontsize-rem(18px);
      @include regular();

      span {
        @include regular();
        color: $grey-b; } } }

  &__search-result {
    max-width: 1110px;
    min-width: 992px;
    margin: 20px auto;
    overflow: hidden;
    transition: 0.3s;

    &_wrapper {
      position: relative;
      width: 100%;
      padding: 10px;
      padding-bottom: 20px;
      border-radius: 5px;
      border: 1px solid #d5d5d5;
      display: flex;
      align-items: flex-start;

      &_close {
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 3px;
        border: 1px solid #a5a7a7;
        cursor: pointer;
        transition: 0.3s;
        outline: none;

        &:hover {
          background-color: #f1f1f1;
          transition: 0.3s; } } }

    &_item {
      margin-right: 10px;
      margin-bottom: 4px; }

    &_save-contact {
      width: 98%;
      height: 190px;
      margin-top: 5px;

      .fields {
        margin-top: 6px !important;
        margin-bottom: 6px !important; }

      &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-select {
          .ant-select-search__field {
            padding: 0 !important;
            border: none !important; } }

        &_select {
          margin-right: 10px; } } }


    &_info {
      width: 50%;
      display: flex;
      flex-wrap: wrap;

      &__tables {
        width: 100%;
        margin-right: 10px;
        display: flex;
        justify-content: space-between;

        .info-table-left, .info-table-right {
          width: 48%; } }

      .table-item {
        display: flex;

        &__name {
          width: 90px;
          padding: 0 4px;
          border: 1px solid #7d7d7d;
          border-bottom: none;
          font-weight: 600;
          font-size: 13px;
          background-color: #f1f1f1;
          color: #7b7b7b; }

        &:first-child {
          .table-item__name {
            border-top-left-radius: 3px; } }

        &:last-child {
          .table-item__name {
            border-bottom: 1px solid #7d7d7d;
            border-bottom-left-radius: 3px; } }

        &__value {
          display: flex;
          align-items: center;
          width: calc(100% - 90px);
          padding: 0 4px;
          border: 1px solid #a3a3a3;
          border-bottom: none;
          border-left: none;
          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto; }

        &:first-child {
          .table-item__value {
            border-top-right-radius: 3px; } }

        &:last-child {
          .table-item__value {
            border-bottom: 1px solid #a3a3a3;
            border-bottom-right-radius: 3px; } } }

      &__label {
        @include fontsize-rem(15px);
        @include bold-italic();
        margin-right: 5px; }

      &__text {
        position: relative;
        @include fontsize-rem(15px);
        @include regular();

        img {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10); } } }

    &_map {
      width: 50%;

      .map-wrapper {
        height: 285px; }

      &__no {
        width: 100%;
        height: 225px;
        background-color: #D5D5D5;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        @include fontsize-rem(25px);
        @include regular(); } } } }

.age-blink-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
  animation: dotAge 1s linear infinite; }


@keyframes dotAge {
  50% {
    opacity: 0; } }


