@import "../../styles/main.sass";

.robots-edit {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;

  &.--fixed {
    padding-top: 65px; }

  .robots-clone {
    display: flex;
    gap: 7px;

    &__button {
      &_text {
        margin-top: 2px; } } } }

.robot-view-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;

  &__left-part {
    width: 30%;
    min-width: 290px; }

  &__right-part {
    width: 70%;
    min-width: 685px;
    margin-bottom: 20px; }

  .ant-card-body {
    max-height: 200px !important;
    overflow: auto; }

  .offline-card-content {
    &__item {
      padding: 10px 0;
      border-top: 1px solid black;

      &:first-child {
        padding-top: 0;
        border-top: none; }
      &:last-child {
        padding-bottom: 0; }

      &_info {
        margin-bottom: 5px; } } } }
