@import "../../../styles/main";

.expandable-table-component-group {
  position: relative;
  width: 100%;
  margin: 15px 0;

  .ant-table-wrapper {
    .ant-table-thead, .ant-table-tbody {
      tr {
        th {
          word-break: keep-all !important; } } }

    .ant-table-thead {
      tr {
        th {
          background: #f2f2f2 !important; } } }

    .leader-column {
      text-align: center; }

    .ant-table-row {
      &:hover {
        td {
          background: #fdfdfd !important; } } }


    .expandable-content {
      position: relative;
      width: 100%;
      display: flex; } }

  &__show-all {
    position: absolute;
    bottom: 10px;
    left: 12px;
    z-index: 1; }

  &__expand-info {
    width: 100%;

    &_wrapp {
      width: 100%;
      display: flex;
      justify-content: space-between; }

    .expanded-row {
      width: 47%;

      &__item {
        display: flex;

        &_name {
          width: 110px;
          padding: 0 4px;
          border: 1px solid #7d7d7d;
          border-bottom: none;
          font-weight: 600;
          font-style: italic;
          font-size: 13px;
          background-color: #ececec;
          color: #7b7b7b; }

        &:first-child {
          .expanded-row__item_name {
            border-top-left-radius: 3px; } }

        &:last-child {
          .expanded-row__item_name {
            border-bottom: 1px solid black;
            border-bottom-left-radius: 3px; } }

        &_value {
          width: calc(100% - 110px);
          padding: 0 4px;
          border: 1px solid #9b9b9b;
          border-bottom: none;
          border-left: none;
          background-color: white;
          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto; }

        &:first-child {
          .expanded-row__item_value {
            border-top-right-radius: 3px; } }

        &:last-child {
          .expanded-row__item_value {
            border-bottom: 1px solid #9b9b9b;
            border-bottom-right-radius: 3px; } } } }

    .expanded-row-location {
      margin-top: 10px;
      width: 100%;

      &__item {
        display: flex;

        &_name {
          width: 128px;
          padding: 0 4px;
          border: 1px solid #7d7d7d;
          font-weight: 600;
          font-style: italic;
          background-color: #ececec;
          color: #7b7b7b;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px; }

        &_value {
          width: 100%;
          padding: 0 4px;
          border: 1px solid #9b9b9b;
          border-left: none;
          background-color: white;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto; } } }

    &_name {
      @include fontsize-rem(14px);
      @include bold-italic();
      margin-right: 5px;
      white-space: nowrap; }

    &_text {
      @include fontsize-rem(14px); }

    &_span {
      margin-right: 7px; } } }
