@import "../../styles/main.sass";

.presence-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; }

  .img-lte-location {
    width: 20px; }

  .ant-pagination {
    text-align: right;
    margin-bottom: 20px; }

  .ant-table-body {
    max-height: 700px;
    overflow-y: auto; }

  .ant-table-row {
    td {
      padding: 10px 16px; } }

  .ant-select {
    margin-right: 8px; }

  .table-cell-form {
    &__inputs {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .ant-input-group-wrapper {
        margin-top: 4px;
        margin-bottom: 4px;
        margin-right: 8px; }

      .ant-select-selection {
        margin-top: 4px;
        margin-bottom: 4px; }

      .checkbox-other {
        display: flex;
        align-items: center;
        justify-content: center; } } }

  .ant-table-thead {
    .ant-table-column-has-sorters {
      cursor: pointer;
      &:hover {
        background-color: #e4e4e4 !important; } }

    .ant-table-column-sorter {

      .anticon {
        font-size: 14px; }
      .on {
        color: $blue; } } } }

.correlation-container {}

.group-container {
  &__add-sensor {
    display: flex;
    align-items: center;

    .add-sensor-button {
      margin-left: 10px; } }

  &__tab {
    margin-top: 20px; } }
