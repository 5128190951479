@import "../../../styles/main.sass";

.admin-content {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &__header {
    @include regular();
    @include fontsize-rem(29px);
    color: black;
    text-align: center;
    margin: 20px 0; }

  &__table-name {
    @include fontsize-rem(24px);
    @include regular(); }

  &__drop-down {
    margin-bottom: 20px;
    width: 40%; }

  &__drop-down-white-list {
    margin-bottom: 20px;
    width: 60%;

    .login-span {
      @include fontsize-rem(12px); } }

  &__white-list {
    width: 100%;

    &_explanation {
      @include fontsize-rem(15px);
      @include regular-italic();
      color: $grey-4;
      line-height: 20px; }

    &_text-area {
      width: 500px;
      @include fontsize-rem(14px);
      @include regular();
      color: black;
      margin-top: 15px;
      margin-bottom: 10px;

      &::placeholder {
        color: $grey-c;
        @include regular(); } } } }
