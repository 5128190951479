// Calculate REMS FROM PX
$base-font-size: 16px;

@function calculateRem($size) {
  $remSize: $size / $base-font-size;
  @return #{$remSize}rem; }

@mixin fontsize-rem($size) {
  font-size: $size;
  font-size: calculateRem($size); }


// Font Face mixin

@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff')format('woff'), url('#{$file-path}.ttf') format('truetype'), url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style; } }

// Vertical align mixin

/* Mixin */
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%); }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); } //IE8
