@import "../../../styles/main.sass";

.job-wrapper {
  margin-bottom: 10px;

  &--opened {
    .job-item {
      border-radius: 4px 4px 0 0;

      &__arrow {
        opacity: 1;
        bottom: -3px;

        &:hover {
          bottom: 0; }

        &.--arrow-down {
          transform: translateX(-50%) rotate(135deg); } } }

    .job-content {
      height: auto; } } }

.job-item {
  position: relative;
  width: 100%;
  height: 60px;

  background-color: #fdfdfd;

  border-radius: 4px;

  display: flex;
  align-items: center;

  color: #1c1c1c;

  box-shadow: 0 1px 4px 0 rgba(21,27,38,.08);
  box-sizing: border-box;
  transition: box-shadow 100ms,transform 100ms,background-color 100ms,border-color 100ms;

  &:hover {
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px 2px rgba(21, 27, 38, 0.08);

    .job-item__arrow {
      opacity: 1; }

    .job-item__icon {
      .anticon {
        background: white; } } }

  // ICON setup
  &__icon {
    width: 5%;
    text-align: center;

    .anticon {
      font-size: 23px;

      border: 2px solid #e7e7e7;
      border-radius: 50%;
      padding: 7px; }

    &--unseen {
      .anticon {
        border: 2px solid #49cd45; } }

    &--fav {
      .anticon {
        border: 2px solid #58b4f5; } } }

  // NAME setup
  &__name {
    width: 45%;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;

    &_type {
      width: 100%;
      font-style: italic;
      color: #656565;
      @include fontsize-rem(12px); }

    &_text {
      @include bold();
      @include fontsize-rem(18px);

      .item__title-name-wrapper {
        display: flex;
        span {
          &:first-child {
            margin-right: 5px; } }
        &_error {
          color: #ee3e43; }
        &_label {
          color: #929292;
          &__location {
            line-height: 22px;
            width: 450px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            //@media only screen and (max-width: 1100px)
 } } } } }            //  width: 345px


  // NUMBER INFO setup
  &__num-info {
    width: 20%;
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;

    @include fontsize-rem(12px);
    @include semibold();

    &:hover {
      .job-item__num-info_copy {
        opacity: 1; } }

    &_row {
      position: relative;

      &:first-child {
        margin-bottom: 2px; } }

    &_title {
      color: #c6c6c6;
      margin-right: 5px; }

    &_value {
      color: #666666; }

    &_copy {
      margin-left: 10px;
      opacity: 0;
      cursor: pointer;
      color: #c1c1c1;

      &:hover {
        color: #8c8c8c; } } }

  &__graph {
    width: 10%; }

  &__actions {
    width: 5%;
    text-align: center;

    .anticon {
      width: 28px;
      height: 28px;
      background-color: transparent;
      margin: 0 auto;
      border-radius: 50%;
      font-size: 23px;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      &:hover {
        background: #dddddd; }

      &:active {
        background: #d2d2d2; } } }

  &__arrow-wrapper {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    cursor: pointer; }

  &__arrow {
    opacity: 0;
    position: absolute;

    bottom: 5px;
    left: 50%;
    padding: 7px;

    box-shadow: 1px -1px 0 1px #cbcbcb inset;
    -webkit-box-shadow: 2px -2px #cbcbcb inset;
    border: solid transparent;

    transition: 0.2s;

    &:hover {
      bottom: 2px;
      box-shadow: 2px -2px 0 2px #a4a4a4 inset;
      -webkit-box-shadow: 2px -2px #a4a4a4 inset; }


    &.--arrow-down {
      transform: translateX(-50%) rotate(-45deg); } } }

