@import "../../../../styles/main.sass";

.job-content {
  width: 100%;
  height: 0;
  background-color: #e9e9e9;
  overflow: hidden;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  transition: .2s;

  &__wrapper {
    padding: 18px; }

  &__error {
    line-height: 18px;
    &_title {
      @include bold(); } }

  &__location-result {
    display: flex;
    animation: mapFadein 1s;

    .dashboard-page__search-result_info {
      width: 30%;

      .dashboard-page__search-result_info__tables {
        display: inline-block;

        .info-table-left, .info-table-right {
          width: 100%;
          margin-bottom: 10px; } } }

    .dashboard-page__search-result_info__tables {
      .table-item {
        &__value {
          font-size: 14px; }

        &__name, &__value {
          padding: 2px 3px;
          background: white; } } }

    .dashboard-page__search-result_save-contact {
      height: auto; }

    .dashboard-page__search-result_map {
      width: 70%;

      .leaflet-container {
        height: 357px; } } }

  &__default-result {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &_row {
      margin-bottom: 4px;
      max-width: 500px; }

    &_header {
      @include bold();
      margin-bottom: 10px;
      line-height: 16px; }

    &_label {
      color: gray;
      margin-right: 5px; }

    &_text {
      color: black; }

    &--split {
      display: flex;

      div {
        &:first-child {
          margin-right: 20px; } } }

    .dashboard-page__search-result_info {
      width: 48%;
      flex-direction: column;

      &.--full-width {
        width: 100%; } }

    .table-item__name {
      width: 110px !important;
      background-color: #ffffff !important; }

    .table-item__value {
      width: calc(100% - 110px) !important;
      background-color: #ffffff !important;
      font-size: 14px; } } }


@keyframes mapFadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
