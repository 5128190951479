@import "../../styles/main.sass";

.terminal-component {
  position: relative;
  border: 1px solid $grey-c;
  border-radius: 5px;
  //overflow: hidden
  display: flex;
  height: 45px;
  margin: 10px 0 10px 10px;
  width: 100%;

  &__ident-color {
    position: absolute;
    width: 10px;
    height: 100%;
    left: -17px;
    border-radius: 4px; }

  .button-handler-component {
    //border-left: 1px solid $grey-c
    border-right: 1px solid $grey-c;
    .handler-children-component {
      height: 43px; } }


  &.--archived {
    background-color: #f1eeec;

    .terminal-component__check {
      pointer-events: none; } }

  &__contacts {
    position: relative;
    width: 70%;
    @include fontsize-rem(13px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    //padding-left: 10px

    &_info {
      margin-left: 5px;

      &.--no-meta {
        font-style: italic;
        font-size: 13px; }

      &_data {
        border-radius: 4px;
        padding: 0 4px; } }

    .input {
      padding-left: 4px; } }

  &__buttons-edit {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 17px;
      padding: 0;
      margin: 0 !important; } }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 !important;
    width: 100%;
    height: 100%;

    &_button {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      //background-color: #bfbfbf
      margin: 0 !important;
      cursor: pointer;
      transition: 0.3s;

      &.--selected {
        background-color: #9ed459 !important; }

      &.--disabled {
        opacity: 0.3;
        pointer-events: none; }

      img {
        width: 14px; }

      .anticon {
        font-size: 14px; }

      &:hover {
        color: black; }

      &:focus {
        outline: none; }

      &:nth-child(4) {
        &:hover {
          color: #fb5d5d; } } } }


  &__check {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;

    &.--checked {
      background-color: #9ed459 !important;
      color: white !important;
      transition: 0.3s; }

    &.--not-allowed {
      background-color: #86a95d !important;
      cursor: no-drop; }

    &:hover {
      background-color: lighten(#9ed459, 35%); }

    &:focus {
      outline: none; } } }
