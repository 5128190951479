@import "../../styles/main.sass";

.add-drop-down-component {
  width: 100%;
  margin-top: 10px;
  transition: 0.3s;

  &__buttons-area {
    display: flex;
    margin-bottom: 10px;
    gap: 30px;

    &_button {
      display: flex;
      gap: 6px;
      cursor: pointer;
      align-items: center;
      font-size: 16px;

      .button-text {
        margin-top: 2px; }

      &:hover {
        color: green;
        transition: 0.3s; }


      &.--close-button {
        .button-icon {
          color: red;
          transition: 0.3s; } }

      &.--close-button {
        &:hover {
          color: darken(red, 10%);
          transition: 0.3s;

          .button-icon {
            color: darken(red, 10%);
            transition: 0.3s; } } } } }

  &.disabled {
    pointer-events: none;
    color: #b6b6b6;
    transition: 0.3s; }

  &__expand-area {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: 0.3s;

    &.--hide {
      display: none; } } }
