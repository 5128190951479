@import "../../../styles/main.sass";

.contact-component {
  margin-bottom: 10px !important;
  width: 90% !important;
  min-width: 290px !important;
  max-width: 320px !important;
  min-height: 200px !important;

  .ant-skeleton {
    width: 90%;
    margin: 20px auto; }

  &__edit-contact {
    display: flex;
    margin-right: 20px !important;
    cursor: pointer;

    &:hover {
      i, span {
        transition: .2s;
        color: green; } } }



  .content {
    overflow: hidden;

    img {
      opacity: .8; }

    .header {
      margin-bottom: 10px;
      font-size: 18px !important; } }

  &_meta {
    margin: 3px 0;

    &.--no-meta {
      font-style: italic;
      font-size: 15px; } }

  &_description {
    margin-top: 12px;
    margin-bottom: 5px;
    color: black !important; }

  .description {
    color: rgba(0,0,0,.4) !important;
    line-height: 1.15rem; }

  &_buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center; }

  &_extra-content-wrapper {
    padding: 0 10px !important;
    overflow: hidden !important;
    transition: 0.3s !important; }

  &_edit-content {
    margin: 10px 0;

    .form {
      margin-bottom: 10px; }

    .edit-content-textarea {
      margin: 4px 0 !important; } }


  &_input {
    width: 100%;
    margin: 3px 0;

    .label {
      width: 25%; } }

  &__add-terminal-wrapper {
    overflow: hidden;
    transition: 0.3s; }

  &__add-terminal {
    display: flex;
    cursor: pointer;

    &:hover {
      i, span {
        transition: .2s;
        color: green; } }

    &_add-button {
      color: $grey-a;
      cursor: pointer;
      transition: 0.3s;
      display: flex;
      align-items: center; }

    &_close-button {
      color: red;
      cursor: pointer;
      transition: 0.3s;
      display: flex;
      align-items: center;
      margin-left: 10px;

      &:hover, &:focus {
        color: darken(red, 5%);
        transition: 0.3s; } }

    &_add-header {
      @include fontsize-rem(13px);
      margin-left: 0; }

    &_add-header-close {
      color: red;
      @include fontsize-rem(13px);
      margin-left: 0; }

    &_form {
      margin: 10px 0;

      &__save {
        margin-top: 10px !important; }

      &__is-primary {
        display: flex;
        align-items: center;
        margin-top: 2px; } } }

  &__locate-wrapper {
    display: flex;
 }    //align-items: center

  &__locate-dropdown {
    width: 15%;
    //height: 100%
    padding: 8px;
    text-align: center;
    background: #E0E1E2;
    border-right: 1px solid #b0b0b0;
    cursor: pointer;

    .anticon {
      font-size: 14px !important; }

    &:hover {
      background: #dddddd;
      .anticon {
        color: #56a7d2; } } }

  &__locate-button {
    width: 85%;
    height: 33px;
    border-radius: 0 !important;
    margin-right: 0 !important;
    padding: 10px !important;
    &:not(:last-child) {
      border-right: 1px solid #b0b0b0 !important; } }

  &__active-passive-group {
    height: 33px;

    &_active {
      padding: 4px 12px 4px 4px !important;
      border-radius: 0 !important; }

    &_passive {
      padding: 4px 4px 4px 12px !important;
      border-top-right-radius: 0 !important; } } }

