@import "../../../styles/main";

.expandable-table-component-contact {
  position: relative;
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  //overflow: hidden
  background: $background-card-light;
  padding: 10px;

  box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.1);
  //box-shadow: 0 1px 2px rgba(0,0,0,0.03),0 2px 4px rgba(0,0,0,0.03), 0 4px 8px rgba(0,0,0,0.02), 0 8px 16px rgba(0,0,0,0.02), 0 16px 32px rgba(0,0,0,0.02), 0 32px 64px rgba(0,0,0,0.02)

  .ant-select-sm {
 }    //width: 100px !important

  .ant-table-wrapper {
    @include fontsize-rem(10px);
    .ant-table-thead, .ant-table-tbody {
      tr {
        th {
          word-break: keep-all !important; } } }

    .ant-table-content {
      padding-top: 10px; }

    .ant-table-thead {
      tr {
        th {
          background: #f2f2f2 !important; } } }

    .ant-table-tbody {
      tr {
        td {
          word-break: normal !important;
          padding: 5px 8px !important; }

        &:last-child {
          td {
            border-bottom: none; } } } }

    .leader-column {
      text-align: center; }

    .ant-table-row {
      .ant-spin-container {
        position: relative;

        .img-lte-location {
          position: absolute;
          width: 18px;
          height: 18px;
          left: 85px;
          top: -10px; } }

      .location-name {
        position: relative;

        .ant-spin-container {
          overflow: initial; }

        .ant-spin-dot {
          left: 40%; }

        .table-location-name {
          position: relative;
          height: 30px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          &__loading-percentage {
            position: absolute;
            left: 50%; }

          &__type {
            width: 100%;
            font-style: italic;
            font-size: 11px;
            color: gray;
            line-height: 9px; }

          &__name {
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }

          img {
            position: absolute;
            width: 24px;
            height: 24px;
            left: 20px; } }

        .table-location-name-color {
          position: absolute;
          width: 7px;
          height: 120%;
          left: -45px;
          top: -3px;
          border-radius: 5px; } }

      .country {
        text-align: left; }

      &:hover {
        td {
          background: #fdfdfd !important; } } } }

  &__expandable {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &_other-information {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .expanded-row {
        width: 47%;

        &__item {
          display: flex;

          &_name {
            width: 110px;
            padding: 0 4px;
            border: 1px solid #7d7d7d;
            border-bottom: none;
            font-weight: 600;
            font-size: 13px;
            background-color: #ececec;
            color: #7b7b7b; }

          &:first-child {
            .expanded-row__item_name {
              border-top-left-radius: 3px; } }

          &:last-child {
            .expanded-row__item_name {
              border-bottom: 1px solid #7d7d7d;
              border-bottom-left-radius: 3px; } }

          &_value {
            width: calc(100% - 110px);
            padding: 0 4px;
            border: 1px solid #9b9b9b;
            border-bottom: none;
            border-left: none;
            background-color: white;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto; }

          &:first-child {
            .expanded-row__item_value {
              border-top-right-radius: 3px; } }

          &:last-child {
            .expanded-row__item_value {
              border-bottom: 1px solid #9b9b9b;
              border-bottom-right-radius: 3px; } } } }


      .info-location-param {
        margin-right: 10px;

        &__title {
          @include bold-italic();
          @include fontsize-rem(14px); } } }

    .expanded-row-location {
      margin-top: 10px;
      width: 100%;
      &__item {
        display: flex;

        &_name {
          width: 128px;
          padding: 0 4px;
          border: 1px solid #7d7d7d;
          font-weight: 600;
          background-color: #ececec;
          color: #7b7b7b;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          &.--location-error-code {
            color: red; } }

        &_value {
          width: 100%;
          padding: 0 4px;
          border: 1px solid #9b9b9b;
          border-left: none;
          background-color: white;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto; } } }

    &_interval {
      width: 40%;

      &__header {
        @include fontsize-rem(14px);
        @include bold-italic(); } } }

  .ant-spin-container {
    position: relative;

    .ant-table {
      margin-top: 35px; }

    .ant-pagination {
      position: absolute;
      margin: 0;
      top: -25px;
      right: 0; } }

  .row-select {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1; }

  .ant-table-column-sorter-down, .ant-table-column-sorter-up {
    &:hover {
      &:after {
        background-color: #c8c8c8 !important;
        opacity: 0.4; } } } }





