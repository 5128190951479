@import "../../styles/main.sass";

.notice-component {
  width: 100%;
  margin-bottom: 20px;
  background: $background-card;

  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: hidden;

  &__inner {
    position: relative;
    padding: 15px;

    &_header {
      position: absolute;
      left: 15px;
      top: 15px;
      @include bold();
      @include fontsize-rem(14px); }

    &_message {
      margin-top: 15px;
      font-size: 14px; } } }
