@import "../../styles/main.sass";

.cell-data-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 50px;

  &.--fixed {
    padding-top: 65px; }

  .cell-import-table {
    //.ant-table-filter-trigger-container
    ////border-right: 1px solid #eaeaea
    thead {
      tr {
        .ant-table-cell {
          border-right: 1px solid #eaeaea; } } } }


  &__drop-zone {
    width: 100%;
    height: 100%;
    cursor: pointer;

    &_percent {
      width: 200px;
      height: 23px;
      border-radius: 4px;
      border: 1px solid #c5c5c5;
      margin: 0 auto;
      margin-top: 15px;
      transition: 0.3s; }

    &_percent-count {
      height: 100%;
      background-color: #01b801;
      display: flex;
      align-items: center;
      justify-content: center; }

    &_percent-done {
      color: white;
      font-size: 14px;
      transition: 0.3s; }

    &_wrapper {
      width: 100%;
      height: 100%;
      border: 3px dotted #bdbcbc;
      padding: 10px;
      background-color: #f7f7f7;
      text-align: center; }

    &_icon {
      font-size: 40px;
      color: cornflowerblue; }

    &_text {
      @include fontsize-rem(17px);
      @include regular();
      margin-bottom: 10px; }

    &_hint {
      @include fontsize-rem(14px);
      @include light(); } }

  &__table-files {
    margin: 10px 0;

    .ant-table-scroll {
      font-size: 12px; }

    &_name {
      @include fontsize-rem(24px);
      @include light();
      color: black;
      margin-bottom: 10px; }

    .ant-table-row {
      td {
        padding: 10px 16px !important; } }

    .table-percentage {

      &__cell {
        border: 1px solid grey;
        width: 100%;
        height: 20px;
        border-radius: 3px;
        overflow: hidden;
        position: relative;

        &_number {
          border-radius: 3px;
          overflow: hidden;
          background-color: #00b800;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          @include fontsize-rem(12px);
          @include light();
          padding: 3px; }

        &_out-number {
          position: absolute;
          left: 50%;
          top: 3px;
          transform: translate(-50%);
          color: black;
          @include fontsize-rem(12px);
          @include regular(); } } } }

  &__files-system {
    margin-bottom: 30px;

    &_wrapper {
      display: flex;
      flex-wrap: wrap; }

    &_table-folders, &_table-files {
      margin-right: 20px;
      height: fit-content !important;

      .ant-list-empty-text {
        padding: 5px 17px; } }

    &_delete {
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        color: red;
        transition: 0.3s; } } }

  &__table-cell {
    margin: 10px 0;
    margin-bottom: 30px;

    .ant-table-tbody {}

    .ant-table-row {
      td {
        padding: 10px 16px !important; } }

    &_name {
      @include fontsize-rem(24px);
      @include light();
      color: black;
      margin-bottom: 10px; }

    .button-action {
      padding: 7px;
      min-width: 55px;
      height: 28px; }

    .cell-pagination {
      text-align: right;
      margin-bottom: 15px; }

    .table-cell-form {
      &__inputs {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .ant-input-group-wrapper {
          margin-top: 4px;
          margin-bottom: 4px;
          margin-right: 8px; }

        &_checkbox {
          display: flex;
          text-transform: uppercase;
          font-size: 14px;

          span {
            display: inline-flex;
            align-items: center; } } } }

    .ant-table-content {
      .ant-table-thead {
        tr {
          .fixed-actions-wrapper {
            padding-left: 23px; } } } } } }

