@import "../../styles/main.sass";

.user-profile-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; }

  &__header {
    @include regular();
    @include fontsize-rem(29px);
    color: black;
    text-align: center;
    margin: 20px 0; }

  &__form-block {
    margin-bottom: 32px; }

  &__sound-selects {
    display: flex;
    align-items: center;
    margin-left: 20px; }

  &__select {
    margin-left: 10px;
    .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center; } }

  &__drop-down-select-sound {
    li {
      display: flex; } }


  &__sound-select {
    height: 25px;
    width: 258px;
    margin-left: 15px;
    outline: none; } }

