@import "../../../../styles/main";

.team-management-component {
  width: 100%;
  margin: 15px 0;
  margin-bottom: 70px;

  &__wrapper {
    position: relative;

    .ant-card-head {
      background-color: whitesmoke; }

    .ant-card-body {
      max-height: 428px;
      overflow-y: auto; } }

  &__cards {
    display: flex;
    flex-wrap: wrap;

    .ant-card-head {
      background-color: white; }


    .ant-card-body {
      max-height: none;
      overflow-y: initial; }

    &_card {
      position: relative;
      margin: 10px 10px;
      margin-bottom: 20px;
      cursor: auto;

      .card-span {
        margin-right: 5px;
        color: black;
        @include bold(); }

      &__input {
        border: none;
        padding-top: 0 !important;

        &:focus {
          outline: none;
          box-shadow: none !important; } } } }

  &__info {
    display: flex;

    &_name {
      @include fontsize-rem(25px);
      @include bold(); }

    &_date {
      @include light();
      @include fontsize-rem(15px);
      margin: 3px 0; }

    &_add-user {
      position: absolute;
      top: 10px;
      right: 20px;

      .spinning {
        margin-right: 10px; } } }

  &__users {
    position: relative;

    &:before {
      content: '';
      width: 1px;
      height: 80%;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      background-color: $grey-c; }

    &_wrapper {
      display: flex;

      .make-lead {
        &:focus, &:active {
          outline: none; } } } }

  &__drop-down {
    width: 30%; }

  &__delete {

    &_form {

      &__text {
        @include fontsize-rem(14px);
        @include regular();
        max-width: 300px; }

      &__mode {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        width: 200px;
        margin-right: 10px;

        &_radio {} }

      &__select {
        .ant-select-selection__rendered {
          .ant-select-search__field {
            border: none !important;
            padding-left: 0 !important; }

          &:focus {
            outline: none; } } } }

    &_button {
      position: absolute;
      bottom: 12px;
      right: 10px;
      color: red;
      @include fontsize-rem(14px);
      @include regular();
      cursor: pointer;
      width: 44px;

      &:focus {
        outline: none; } } } }

