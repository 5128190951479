@import "../../../styles/main.sass";

.app--dark-mode {
  .selected-flag {
    .flag {
      filter: invert(1); } }
  .flag-dropdown {
    .country-list {
      .flag {
        filter: invert(1); } } } }

.change-phone-email {

  .app--dark-mode {
    .flag {
      filter: invert(1); } }

  .field {
    max-width: 300px;
    margin-bottom: 1em; }

  .react-tel-input {
    margin-left: 8px;
    .form-control {
      padding-left: 50px !important; } } }
