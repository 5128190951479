@import "../../styles/main.sass";

.user-settings-page {
  min-width: 1024px;

  &.--fixed {
    padding-top: 65px; }

  &__header {
    @include regular();
    @include fontsize-rem(29px);
    color: black;
    text-align: center;
    margin: 20px 0; } }
