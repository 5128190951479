@import "../../../styles/main.sass";

.change-time-zone {

  &__input-group {
    display: flex;
    align-items: flex-start;

    .timezone-picker {
      margin: 0 1em 0 0;
      width: 284px;

      .timezone-picker-textfield {
        input {
          padding: 8px 12px; } } }

    .timezone-button {
      height: 35px;
      font-size: 0.9rem;
      margin-left: 2px !important; } } }
