// Breakpoint variables for mobile first design
$sm: 768px; // Small devices ex: Tablets
$md: 992px; // Medium devices ex: Desktops
$lg: 1300px; // Large devices ex: Large Desktops

$xs-portrait: (max-device-width 767px) (orientation portrait);
$xs-landscape: (max-device-width 767px) (orientation landscape);

$sm-portrait: (min-width 768px) (orientation portrait);
$sm-landscape: (min-device-width 768px)  (max-device-width 1024px)  (orientation landscape);
