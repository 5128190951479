@import "../../styles/main.sass";

.robot-info-view {
  margin: 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;

  svg {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px; }

  &__header {
    width: 90%;
    margin-bottom: 10px;
    font-size: calculateRem(20px) !important;
    @include bold();
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis; }

  &__description-title {
    margin-bottom: 5px;
    color: black; }

  .meta {
    div {
      margin: 5px 0; } }

  .description {
    color: rgba(0, 0, 0, 0.4)!important; }

  .button {
    width: 100%; }

  &__clone {
    &_button {
      color: green;
      @include fontsize-rem(14px);
      @include regular();
      cursor: pointer;
      width: 60px;
      margin-top: 5px; } }

  &__delete {
    &_button {
      color: red;
      @include fontsize-rem(14px);
      @include regular();
      cursor: pointer;
      width: 60px;
      margin-top: 5px; } }

  .button-handler-wrapper {
    width: 50px; } }
