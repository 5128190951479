@import "../../styles/main.sass";

.group-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  .robot-info-view {
    width: calc((100% / 4) - 20px + (20px / 4)) !important;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); } }

  &.--fixed {
    padding-top: 65px; }

  &__drop-down {
    &_select-contact {
      margin-right: 10px; } }

  &__tab-area {
    position: relative;

    &_group-name {
      position: absolute;
      top: 8px;
      right: 20px;
      @include regular();
      @include fontsize-rem(18px);
      display: flex;
      align-items: center;

      &__edit {
        display: flex;

        button {
          margin-left: 10px; } }

      &__name {
        display: flex;
        align-items: center;

        button {
          margin-left: 10px; } } } }

  .ui {
    .expandable-table-component-group {
      margin-bottom: 0;
      margin-top: 0; }

    tbody {
      tr {
        td {
          padding: 5px 7px !important; } } }

    .ant-pagination {
      margin: 16px 0 9px !important; } }

  .map-wrapper {
    margin-top: 30px;
    height: 300px; }

  &__all-locations {
    width: 60%;
    &__table {
      margin-top: 5px !important;
      .ant-pagination {
        top: -40px !important; } }

    &__button-all {
      position: relative;
      top: -2px;
      left: 12px; } }

  &__all-locations-map {
    width: 40%;
    height: 250px;
    margin-top: 37px;
    margin-left: 10px;

    border-radius: 5px;
    overflow: hidden;
    .leaflet-map-component {
      height: 100%;
      max-height: 455px;
      .leaflet-container {
        width: 100%;
        height: 100%;
        margin-top: 0; } } } }


.ant-calendar-picker-clear {
  display: none; }
