@import "../../styles/main.sass";

.survey-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; }

  .ant-table-small {
    margin: 0 !important;

    .ant-table-container {
      width: calc(100% + 2px); }


    .ant-table-thead {
      tr {
        th {
          .anticon-search, .anticon-filter {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%); } } } }

    .ant-table-expanded-row {
      .ant-table-tbody {
        tr {
          &:hover {
            td {
              background: #f6f6f6 !important; } }
          td {
            background: white; } } } } } }

