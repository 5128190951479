@import "../../styles/main.sass";

.navigation-menu {
  margin-bottom: 20px;
  min-width: 1024px;
  position: relative;

  &__watermark {
    position: absolute;
    width: 100%;
    height: 12px;
    background-color: red;
    bottom: -9px;
    font-size: 12px;
    color: white;
    line-height: 12px;
    display: flex;
    justify-content: space-between; }

  &.--fixed {
    position: fixed;
    width: 100%;
    z-index: 1051;

    .ui {
      height: 45px; }

    height: 45px !important;
    box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.1) !important;
    a {
      padding: 7px 15px !important; }
    .button-handler-component {
      a {
        padding: 11px 19px !important; } } }

  .drop-down-item {
    position: relative;

    &_delete {
      position: absolute;
      top: 2px;
      right: 4px;
      z-index: 3;
      cursor: pointer;

      .delete {
        color: #d0d0d0;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;

        &:hover, &:active {
          color: red;
          -webkit-transition: 0.3s;
          -moz-transition: 0.3s;
          -ms-transition: 0.3s;
          -o-transition: 0.3s;
          transition: 0.3s; } } } }

  &__notification {
    margin-left: 30px;
    position: relative;

    &_status {
      position: absolute;
      top: 50%;
      left: -35px;
      @include fontsize-rem(12px);
      @include bold-italic();
      transform: translateY(-50%);

      &.--new {
        color: gold; }

      &.--seen {
        color: $grey-b;
        opacity: 0.6; } }

    &_name {
      @include fontsize-rem(14px);

      &.--regular {
        @include regular(); }

      &.--semi-bold {
        @include semibold-italic(); } }

    span {
      white-space: pre-line; }

    &_info {
      @include fontsize-rem(12px);
      @include light();
      color: #c3cbcd;

      &__collection {
        &_status {
          color: #4c4c4c;
          margin-bottom: 3px;
          white-space: pre-line;

          &.--red {
            color: red !important; }

          &.--green {
            color: green !important; } }

        &_explanation {
          color: #ababab;
          white-space: pre-line; } } }

    &:active, &:focus {
      outline: none; } }

  .drop-down-actions {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5; }

  .drop-down-notifications {
    max-height: 415px !important;
    overflow-y: auto !important;
    min-width: 350px !important;

    .drop-down-item {
      cursor: pointer;
      &:hover {
        background-color: #efefef !important; } } }


  &__credits {
    border-radius: 4px;
    padding: 5px;
    border: 1px solid #949090;
    color: black;
    @include fontsize-rem(13px);
    @include regular(); }

  &__popup-credits {

    &_block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; }

    &_title {
      @include fontsize-rem(14px);
      @include semibold();
      margin-right: 20px; }

    &_number {
      @include fontsize-rem(14px);
      @include regular(); } }

  &__team {
    .team-text {
      max-width: 115px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      i {
        margin-right: 10px; } }

    .drop-down-item {
      cursor: auto !important;
      display: flex;
      align-items: center;

      &_button {
        margin-left: 20px; } } }

  &__user-drop-down {
    .button-handler-component {
      a {
        padding: 10px; } } }


  .button-handler-component {

    &:hover {
      .item {
        cursor: pointer;
        background: rgba(0,0,0,.05)!important;
        color: rgba(0,0,0,.95)!important; } } } }
