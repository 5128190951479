@import "../../styles/main.sass";

.robots-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; }

  &__header {
    @include regular();
    @include fontsize-rem(29px);
    color: black;
    text-align: center;
    margin: 20px 0; }

  .robot-info-view {
    position: relative;
    width: calc((100% / 4) - 20px + (20px / 4)) !important;


    &__header {
      margin-bottom: 30px; }

    &__header-type {
      position: absolute;
      top: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #b6b6b6;
      margin: 0; }

    &__info {
      @include fontsize-rem(15px);

      &_text {
        color: #676767; } }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); } }

  &__robots {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px; } }
