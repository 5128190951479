@import "../../../styles/main";

.expandable-table-component {
  width: 100%;
  margin: 15px 0;

  .ant-table-wrapper {
    .ant-table-thead, .ant-table-tbody {
      tr {
        th {
          word-break: keep-all !important; } } }

    .ant-table-thead {
      tr {
        th {
          background: rgba(0,0,0,.1) !important; } } }

    .leader-column {
      text-align: center; }

    .ant-table-row {
      td {
        padding: 6px 8px !important; }
      &:hover {
        td {
          background: #fdfdfd !important; } } }


    .expandable-content {
      width: 100%;
      display: flex;

      &.--disabled {
        opacity: 0.5;
        pointer-events: none; }

      .checkbox {
        label {
          @include fontsize-rem(14px);
          @include regular(); } }

      &__edit {
        width: 48%;

        .leader-checkbox {
          &.--disabled {
            opacity: 0.5;
            pointer-events: none; } } } }

    .actions-column {
      &__suspend {
        transition: 0.3s;

        &:hover {
          background-color: #e2a93c;
          transition: 0.3s; } } } }


  .permission-table {
    margin-bottom: 20px;
    margin-right: 40px;

    .ant-table-thead {
      tr {
        th {
          background: #FBFBFB !important;
          font-weight: bold; } } }

    .ant-table-tbody {
      tr {
        &:last-child {
          td {
            border-bottom: 1px solid black; } }

        td {
          border: 1px solid black;
          border-bottom: 0;
          border-right: 0;

          &:last-child {
            border-right: 1px solid black; } } } } }


  .permission-wrapper {
    &--disabled {
      opacity: 0.5;
      pointer-events: none; } } }

