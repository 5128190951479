@import "../../styles/main.sass";

.groups-page {
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  padding: 0 20px;

  &.--fixed {
    padding-top: 65px; }

  &__header {
    @include regular();
    @include fontsize-rem(29px);
    color: black;
    text-align: center;
    margin: 20px 0; }

  &__drop-down {
    width: 30%;
    .button {
      margin-right: 10px; } }

  tbody {
    tr {
      cursor: pointer; } }

  .wrap-contacts-group-upload {
    display: flex; }

  ::-webkit-scrollbar {
    width: 4px; }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2); }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2); } }

