@import "../../../styles/main.sass";

.change-notification-method {
  margin-bottom: 32px;

  .field {
    max-width: 300px;
    margin-bottom: 1em; }

  &__wrapper {
    display: flex;

    &_select {
      width: 284px !important; }

    &_button {
      margin-left: 15px !important; } } }
